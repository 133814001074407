<template>
  <div data-cy="benefit-container" class="container">
    <h1 v-if="largeHeader" class="text-center pb-8">
      {{ component.title }}
    </h1>
    <h2 v-else data-cy-benefit-headline="component.title">
      {{ component.title }}
    </h2>

    <div
      data-cy="benefit-section-wrapper"
      class="row align-items-start text-center"
      :class="centerItems ? 'justify-content-center' : 'justify-content-start'"
    >
      <div
        v-for="benefit in component.benefits"
        :key="benefit.id"
        data-cy="benefit-section-benfit-item"
        class="col-lg-3 col-md-6 col-sm-12 text-align-center p-8"
      >
        <strapi-image
          data-cy="benefit-section-icon"
          :force-height="40"
          :image="benefit.icon"
          :alt="benefit.title"
        />
        <h2 data-cy="benefit-section-title" class="my-5">
          {{ benefit.title }}
        </h2>
        <p>{{ benefit.content }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import StrapiImage from '~/components/strapi/StrapiImage.vue';
import type { StrapiBenefit, StrapiBenefitList } from '~/apollo/types/types';

export default defineComponent({
  name: 'StrapiProductBenefits',
  components: {
    StrapiImage,
  },
  props: {
    component: {
      type: Object as PropType<StrapiBenefitList | StrapiBenefit>,
      required: true,
    },
    largeHeader: {
      type: Boolean,
      default: false,
    },
    centerItems: {
      type: Boolean,
      default: true,
    },
  },
});
</script>
